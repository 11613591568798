import React, {useContext} from 'react';
import Moment from 'moment';
import 'moment/locale/de';
import '../css/Pharmacies.css';
import mapIcon from "../images/icon-map.png"
import {MainContext} from "../contexts/MainContext";
import phoneIcon from "../images/icon-phone.png";


Moment.locale('de');

export default function Pharmacies({data, index}) {
    const { selected, setSelected, activeDate, setActiveDate,firstDate } = useContext(MainContext);
    return (
        <div className={`date-list ${(activeDate === Moment(data[0]).format('YYYY-MM-DD')) ? 'active' : ''}`}  key={index}>
            <div className="date" onClick={() => { (activeDate !== null) ? ((activeDate === Moment(data[0]).format('YYYY-MM-DD')) ? setActiveDate(false) : setActiveDate(Moment(data[0]).format('YYYY-MM-DD')) ) : setActiveDate(firstDate); setSelected(); }}>{ Moment(data[0]).format('DD.MM.YYYY (dddd)')}</div>
            <div className="box">
            {data[1].map((pharmacy, i) => {
                return (
                    <div className={selected === pharmacy ? 'pharmacy active' : 'pharmacy'} key={i}>
                        <div className="clearfix">
                        <div className="col-left">
                            <div className="head"  onClick={() => { setSelected(pharmacy) }} ><div className="title">{pharmacy.name}</div></div>
                                <p>{pharmacy.location.street}, {pharmacy.location.zip} {pharmacy.location.city}<br />{ pharmacy.phone && <span><img src={phoneIcon} alt="Telefon" width={24} height={24} /> <a href={`tel:${pharmacy.phone}`} className="phone">{pharmacy.phone}</a><br /></span> }</p>
                                <div className="notdienst"><span>NOTDIENST</span> { Moment(pharmacy.startDateTime.date).format('ddd hh:mm')} - { Moment(pharmacy.endDateTime.date).format('ddd hh:mm')}</div>
                            </div>
                            <div className="col-right">
                                <div className="km">{ ((pharmacy.distanceMetric / 1000).toFixed(1) === "0.0") ? <span style={{ color: "#3ba63b" }}>Sehr nah!</span> : (((pharmacy.distanceMetric / 1000).toFixed(1) < 1) ? Math.round(pharmacy.distanceMetric)+' m' : (pharmacy.distanceMetric / 1000).toFixed(1) + ' km')}
                                <br /><a href={`https://www.google.de/maps/dir//${pharmacy.name}, ${pharmacy.location.street}, ${pharmacy.location.zip} ${pharmacy.location.city}`} target={"_blank"}  rel="noopener noreferrer"><img src={mapIcon}  width={32} height={32} alt="Map"/></a></div>
                            </div>
                        </div>
                        </div>
                )
              })}
            </div>
        </div>
    )
}
