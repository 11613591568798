import React from 'react';

function NoLocation() {
    return (
        <div className="p-3">
            <div className="alert alert-danger" role="alert">Keine Apotheke in Ihrer Nähe gefunden!</div>
        </div>
    )
}

export default NoLocation;
