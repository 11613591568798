import React from 'react';

function NoContent() {
    return (
       <div className="p-3">
           <div className="alert alert-danger" role="alert">Bitte überprüfen Sie die angegebene Daten. Unter diesen Daten wurde kein Ort bzw. keine Postleitzahl gefunden.</div>
       </div>
    )
}

export default NoContent;
