import React,  { useState, useEffect, useCallback } from "react";
import Pharmacy from "./components/Pharmacies";
import NoContent from "./components/NoContent";
import NoLocation from "./components/NoLocation";
import Loading from "./components/Loading";
import './css/bootstrap.min.css';
import './css/App.css';
import _ from 'lodash';
import markerIcon from "./images/icon-marker.png"
import apoIcon from "./images/icon-apotheke.png"
import locationIcon from "./images/icon-location.png"
import mapIcon from "./images/icon-map.png"
import searchIcon from "./images/icon-search.png"
import phoneIcon from "./images/icon-phone.png"
import Moment from 'moment';
import 'moment/locale/de';
import { GoogleMap,useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import {MainContext} from "./contexts/MainContext";

const libraries = ["places"];

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};




function App() {
    const GOOGLEMAP_API_KEY = "AIzaSyCk5QVL5Jkikddv63wF4q0NAelI0zRPBxQ";
    //const GOOGLEMAP_API_KEY = "AIzaSyBrWGYB8uXXov0Jhcb2hFF44ummYvN3dNk";


  let url = window.location.search;
  let params = new URLSearchParams(url);
  const address = params.get('q');

  const [pharmaciesData, setPharmaciesData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [coords, setCoords] = useState(null);
  const [selected, setSelected] = useState(null)
  const [myLocation, setMyLocation] = useState(false)
  const [inputLocation, setInputLocation] = useState();
  const [activeDate, setActiveDate] = useState(null);
  const [firstDate, setFirstDate] = useState(null);
  const [getMap, setMap] = useState(null);

    useEffect(() => {
        if (getMap) {
            if(!selected) {
            const bounds = new window.google.maps.LatLngBounds();
            if(pharmaciesData[activeDate]){
                pharmaciesData[activeDate] && Object.values(pharmaciesData[activeDate]).map(marker => {
                        bounds.extend({
                            lat: Number(marker.location.geographicalPoint.latitude),
                            lng: Number(marker.location.geographicalPoint.longitude),
                        });
                });

            } else {
                pharmaciesData && Object.entries(pharmaciesData).map(marker => {
                    marker[1].map( m => {
                        bounds.extend({
                            lat: Number(m.location.geographicalPoint.latitude),
                            lng: Number(m.location.geographicalPoint.longitude),
                        });
                    })

                })
            }
            getMap.fitBounds(bounds);

            }
        }
    }, [getMap,activeDate,selected]);

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map);
    }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

  useEffect(() => {
    setInputLocation(address);
    setLoading(true);
    function getData(address) {
        (address !== '') && fetch(`/data.php?q=${address}`).then((res) => res.json()).then((data) => {

            const ph = Object.values(data.response.pharmacies);
            if (ph.length > 0) {
                const lat = ph[0].location.geographicalPoint.latitude;
                const lng = ph[0].location.geographicalPoint.longitude;
                setCoords({lat: Number(lat), lng: Number(lng)})

                ph.forEach(function(item, i) {
                    item.startDate =  Moment(item.startDateTime.date).format('YYYY-MM-DD');
                    if(i === 0) {
                        setFirstDate(Moment(item.startDateTime.date).format('YYYY-MM-DD'))
                    }
                })

                const list = _.sortBy(ph, 'startDate');
                const list1 = _.groupBy(list, 'startDate');

                if(activeDate === null) {
                    setActiveDate(Moment(list1[0]).format('YYYY-MM-DD'));
                }
                setPharmaciesData(list1);
            } else {
                setPharmaciesData();
            }
            setLoading(false);

        }).catch(err => {
            setLoading(false);
            setPharmaciesData();
        });

    }
    getData(address);
  }, [address]);



    function getData(address){
        (address) && fetch(`/data.php?q=${address}`).then((res) => res.json()).then((data) => {
            const ph = Object.values(data.response.pharmacies);
            if(ph.length > 0) {
                const lat = ph[0].location.geographicalPoint.latitude;
                const lng = ph[0].location.geographicalPoint.longitude;
                setCoords({lat: Number(lat), lng: Number(lng)})

                ph.forEach(function(item, i) {
                    item.startDate =  Moment(item.startDateTime.date).format('YYYY-MM-DD');
                    if(i === 0) {
                        setFirstDate(Moment(item.startDateTime.date).format('YYYY-MM-DD'))
                    }
                })
                const list = _.sortBy(ph, 'startDate');
                const list1 = _.groupBy(list, 'startDate');


                if(activeDate === null) {
                    setActiveDate(Moment(list1[0]).format('YYYY-MM-DD'));
                }

                setFirstDate(list1[0]);
                setPharmaciesData(list1);

            }

            setLoading(false);

        }).catch(err => {
            setLoading(false);
            setPharmaciesData();
        });
    }

  const getLocation = () => {
    setLoading(true);
    setPharmaciesData();
      setInputLocation(false);
      setMyLocation(true);
      if(!navigator.geolocation){
        alert('Um diensthabende Apotheken in Ihrer Nähe zu finden, müssen Sie Ihren Standort teilen!');
          setLoading(false);
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
          (address) && fetch(`/data.php?lat=${position.coords.latitude}&lng=${position.coords.longitude}`).then((res) => res.json()).then((data) => {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              const ph = Object.values(data.response.pharmacies);

              if(ph.length > 0) {
                  ph.forEach(function(item,i) {
                      item.startDate =  Moment(item.startDateTime.date).format('YYYY-MM-DD')
                      if(i === 0) {
                          setFirstDate(Moment(item.startDateTime.date).format('YYYY-MM-DD'))
                      }
                  })
                  const list = _.sortBy(ph, 'startDate');
                  const list1 = _.groupBy(list, 'startDate');



                  if(activeDate === null) {
                      setActiveDate(Moment(list1[0]).format('YYYY-MM-DD'));
                  }

                  setPharmaciesData(list1);
              }

              setLoading(false);
              setCoords({lat: Number(lat), lng: Number(lng)})

          }).catch(err => {
              setLoading(false);
              setPharmaciesData();
          });
      }, function(error) {
          alert('Um diensthabende Apotheken in Ihrer Nähe zu finden, müssen Sie Ihren Standort teilen!');
          setLoading(false);
      })
    }
  }

 let textInput = React.createRef();

  let searchIt = (e) => {
      setMyLocation(false);
    setLoading(true);
    setSelected(null);
    e.preventDefault();
    getData(textInput.current.value);
  };

  let _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        setMyLocation(false);
      setLoading(true);
      e.preventDefault();
      getData(textInput.current.value);
    }
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLEMAP_API_KEY,
    libraries,
  });


  if (loadError) return "";
  if (!isLoaded) return "";

  const center = coords;

  const data = {
      selected,
      setSelected,
      setMyLocation,
      myLocation,
      activeDate,
      setActiveDate,
      firstDate,
      onLoad
  }

  return (
    <>
    <MainContext.Provider value={data}>
        <div className="App">
            { loading &&  <Loading /> }
            <div className="form">
                <div className="input-group search px-3 ">
                    <input className="form-control" name="search" type="text" placeholder="Ort/PLZ/Straße.." defaultValue={inputLocation} ref={textInput} onKeyDown={ (e) => _handleKeyDown(e) }  />
                    <div className="input-group-append">
                        <button className="btn btn-danger" onClick={ (e) => searchIt(e) }><img src={searchIcon} alt="" width={16} height={16} /></button>
                    </div>
                </div>
                <div className="px-3">
                    <button className="btn btn-primary btn-block" onClick={ () => getLocation() }><img src={locationIcon} alt="" width={16} height={16} /> Mein Standort</button>
                </div>
            </div>
            <div className="dflex">
                {!loading && pharmaciesData &&
                <GoogleMap id="map"
                           panTo={selected && { lat: Number(selected.location.geographicalPoint.latitude),lng: Number(selected.location.geographicalPoint.longitude) }}
                           center={(selected) ? ({ lat: Number(selected.location.geographicalPoint.latitude), lng: Number(selected.location.geographicalPoint.longitude) }) : center}
                           options={options}
                           onLoad={onLoad}
                           onUnmount={onUnmount}
                           zoom={ selected ? 15 : 11 }
                >

                    {
                        (pharmaciesData[activeDate]) && Object.values(pharmaciesData[activeDate]).map((marker, index) => {
                                return (
                                    <Marker
                                        key={index} position={{ lat: Number(marker.location.geographicalPoint.latitude), lng: Number(marker.location.geographicalPoint.longitude) }}
                                        onClick={() => {
                                            setSelected(marker);
                                        }}
                                        icon={{
                                            url: ((marker.distanceMetric / 1000).toFixed(1) === "0.0") ? apoIcon : markerIcon,
                                            origin: new window.google.maps.Point(0, 0),
                                            anchor: new window.google.maps.Point(21, 5),
                                            scaledSize: new window.google.maps.Size(42, 42),
                                        }}
                                    />
                                )
                            })
                    }
                    {selected ? (
                        <InfoWindow
                            position={{ lat: Number(selected.location.geographicalPoint.latitude), lng: Number(selected.location.geographicalPoint.longitude) }}
                            onCloseClick={() => {
                                setSelected(null);
                            }}>
                            <div className="popup">
                                <h3>{selected.name}</h3>
                                <p>{selected.location.street}, {selected.location.zip} {selected.location.city}<br />{ selected.phone && <span><img src={phoneIcon} alt="Telefon" width={16} height={16} /> <a href={`tel:${selected.phone}`} className="phone">{selected.phone}</a><br /></span> }</p>
                                <p><a href={`https://www.google.de/maps/dir//${selected.name}, ${selected.location.street}, ${selected.location.zip} ${selected.location.city}`} target={"_blank"}  rel="noopener noreferrer"><img src={mapIcon}  width={24} height={24} alt="Map"/></a>  { ((selected.distanceMetric / 1000).toFixed(1) === "0.0") ? <span style={{ color: "#3ba63b" }}>Sehr nah!</span> : (((selected.distanceMetric / 1000).toFixed(1) < 1) ? Math.round(selected.distanceMetric)+' m' : (selected.distanceMetric / 1000).toFixed(1) + ' km')}</p>
                                <div className="notdienst">NOTDIENST:<br /> { Moment(selected.startDateTime.date).format('DD.MM.YYYY')} { Moment(selected.startDateTime.date).format('hh:mm')}<br />{ Moment(selected.endDateTime.date).format('DD.MM.YYYY')} { Moment(selected.endDateTime.date).format('hh:mm')}</div>
                            </div>
                        </InfoWindow>
                    ) : null}
                </GoogleMap>
                }
                { !loading && pharmaciesData &&
                <div className="content">
                    <div className="results">
                        { !loading && pharmaciesData && Object.entries(pharmaciesData).map((item, index) => <Pharmacy data={item} index={index} key={index} />) }
                    </div>
                </div>
                }
            </div>
            {  (myLocation) ? (!loading  && !pharmaciesData && <NoLocation />) : (!loading && !pharmaciesData && <NoContent />) }
        </div>
    </MainContext.Provider>
    </>
  );
}

export default App;